import { imovel_repository } from "./store/imoveis";
import { createApp } from 'vue'
import SearchResults from '@components/search-results.vue'
import { search_url } from "./store/search";
import Swal from "sweetalert2";
import { configuracao_layout } from "./store/configuracao-layout";

import 'better-dateinput-polyfill/dist/better-dateinput-polyfill.js'


$(document).on('input', '[name=hora]', function() {
    let value = $(this).val() as string
    value = value.replace(/\D+/igm, '').substring(0, 4)
    value = value.replace(/^[3-9].*/igm, '')
    value = value.replace(/^2[4-9].*/igm, '2')
    value = value.replace(/^([0-1]\d)[6-9].*/igm, '$1')    
    value = value.replace(/^(\d{2})(\d{1,2})/igm, '$1:$2').replace(/\D+$/igm,'')
    $(this).val(value)
})

const el = document.querySelector('.vue-search-results')
if (el) {
    configuracao_layout.value = $(el).data('configuracao_layout')
    const records = $(el).data('records') || []
    
    const app = createApp(SearchResults, {
        records
    }).mount('.vue-search-results')
    

    
}


if ($('[name=form_contato]').length > 0) {
    $('[name=form_contato]').each(function () {
        const $form = $(this);
        const $whatslink = $form.find('[name=whatslink]')
        const whatslink: string = ($whatslink.val() as string) || ''
        const async = whatslink == '';
        /** @ts-ignore */
        $form.validate({
            submitHandler: function () {
                $form.find('label,input[type="text"],input[type="date"],input[type="time"],input[type="checkbox"],input[type="radio"],select,textarea').attr('readonly', 'readonly').css('opacity', '0.6');
                $form.find('input[type="submit"]').attr('disabled', 'disabled').css('opacity', '0.6');
                // Submit o Form
                $.ajax({
                    url: $form.attr('action'),
                    type: 'post',
                    dataType: 'json',
                    async,
                    data: $form.serializeArray()
                }).then(async () => {
                    await Swal.fire({
                        icon: "success",
                        title: "Formulário enviado com sucesso"
                    })

                    if ($whatslink.length && whatslink != '') {
                        window.open(whatslink, '_blank');
                    } else {
                        location.reload()
                    }                    
                }).fail(async error => {
                    await Swal.fire({
                        icon: "error",
                        title: "Oops",
                        text: "Não foi possível enviar o formulário"
                    })
                    /*
                    if ($whatslink.length && whatslink != '') {
                        window.open(whatslink, '_self');
                    } else {
                        location.reload()
                    } 
                    */
                }).always(() => {
                    $form.find('label,input[type="text"],input[type="date"],input[type="time"],input[type="checkbox"],input[type="radio"],select,textarea').prop('readonly', false).css('opacity', '1');
                    $form.find('input[type="submit"]').prop('disabled', false).css('opacity', '1');
                    $form.find('input[type="submit"]').removeAttr('disabled').css('opacity', '1');
                    /** @ts-ignore */
                    $('.modal').modal('hide');
                })
            },
            rules:
            {
                nome: {
                    required: true
                },
                email: {
                    required: true,
                    email: true
                },
                telefone: {
                    required: true,
                    telefone: true
                },
                documento: {
                    required: true
                },
                data: {
                    required: true
                },
                hora: {
                    required: true
                },
                tipo: {
                    required: true
                },
                modo: {
                    required: true
                },
                valor: {
                    required: true
                },
                mensagem: {
                    required: true
                },
                'arquivos[]': {
                    required: true,
                    extension: 'jpg|jpeg|png|eps|svg|tiff|jpe|jfif|gif|webp|webm|mp4|avi|mov|mkv|wmv|flv|ogg|mpg|pdf'
                }
            },
            messages: {
                nome: {
                    required: 'Preencha o Nome'
                },
                email: {
                    required: 'Preencha o E-mail',
                    email: 'E-mail inválido'
                },
                telefone: {
                    required: 'Preencha o Telefone',
                    telefone: 'Número de telefone não é válido'
                },
                documento: {
                    required: 'Preencha o Documento'
                },
                data: {
                    required: 'Preencha a Data'
                },
                hora: {
                    required: 'Preencha a Hora'
                },
                tipo: {
                    required: 'Selecione o Tipo'
                },
                modo: {
                    required: 'Selecione a Finalidade'
                },
                valor: {
                    required: 'Preencha o Valor'
                },
                mensagem: {
                    required: 'Digite a Mensagem'
                },
                'arquivos[]': {
                    required: 'Por selecione o(s) arquivo(s) para Anexar',
                    extension: 'Formato de arquivo não aceito'
                }
            }
        });
    });
}






if (import.meta.hot) {
    import.meta.hot.accept()
}


