import { Sort } from "./sort"

export class Filter {
    dormitorios?: number = 0
    suites?: number = 0
    vagas?: number = 0
    area?: number = 0
    tipos?: number[] = []
    caracteristicas?: number[] = []
    bairros?: number[] = []
    modo?: "comprar"|"alugar"|"" = ""
    valor_de?: number = 0
    valor_ate?: number = 0
    codigo?: string = ''
    empreendimento?: string = ''
    fields: string = 'codigo,slug,titulo,descricao,dormitorios,vagas,suites,area_privativa,valor_venda,valor_locacao,venda,locacao'
    sort: Sort = Sort.RECENTES
}