import { Pagination } from "../pagination";
import { Filter } from "./filter";
import { Imovel } from "./imovel";

import { AxiosInstance } from 'axios'

export class ImovelRepository {
    constructor(private api: AxiosInstance) { }

    async search(filter: Filter = new Filter(), pagination: Pagination = new Pagination()): Promise<Imovel[]> {
        const { data } = await this.api.get<Imovel[]>(`imoveis/search`, {
            params: {
                ...filter,
                ...pagination
            }
        })

        return data
    }

    async count(filter: Filter): Promise<number> {
        const { data } = await this.api.get<number>(`imoveis/count`, {
            params: {
                ...filter
            }
        })
        return data
    }
}